import { NgModule } from '@angular/core';
import { PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'transaction-import',
    pathMatch: 'full'
  }, {
    path: 'transactions-import',
    loadChildren: () => import('./pages/transactions-import/transaction-import.module').then(m => m.TransactionImportPageModule)
  }
  , {
    path: 'activations',
    loadChildren: () => import('./pages/activations/activations.module').then(m => m.ActivationsPageModule)
  },
  {
    path: 'transactions-search',
    loadChildren: () => import('./pages/transactions-search/transactions-search.module').then(m => m.TransactionsSearchPageModule)
  },
  {
    path: 'orders-search',
    loadChildren: () => import('./pages/orders-search/orders-search.module').then(m => m.OrdersSearchPageModule)
  },
  {
    path: 'orders-detail',
    loadChildren: () => import('./pages/orders-detail/orders-detail.module').then(m => m.OrdersDetailPageModule)
  },
  {
    path: 'vehicles-import',
    loadChildren: () => import('./pages/vehicles-import/vehicles-import.page.module').then(m => m.VehiclesImportPageModule)
  }, {
    path: 'users-search',
    loadChildren: () => import('./pages/users-search/users-search.module').then(m => m.UsersSearchPageModule)
  }, {
    path: 'plates-subs-search',
    loadChildren: () => import('./pages/plates-subs-search/plates-subs-search.module').then(m => m.PlatesSubsSearchPageModule)
  }, {
    path: 'vehicles-search',
    loadChildren: () => import('./pages/vehicles-search/vehicles-search.module').then(m => m.VehiclesSearchPageModule)
  },
  {
    path: 'devices-search',
    loadChildren: () => import('./pages/devices-search/devices-search.module').then(m => m.DevicesSearchPageModule)
  },
  {
    path: 'departments-search',
    loadChildren: () => import('./pages/departments-search/departments-search.module').then(m => m.DepartmentsSearchPageModule)
  },
  {
    path: 'devices-import',
    loadChildren: () => import('./pages/devices-import/devices-import.module').then(m => m.DevicesImportPageModule)
  },
  {
    path: 'devices-detail',
    loadChildren: () => import('./pages/devices-detail/devices-detail.module').then(m => m.DevicesDetailPageModule)
  },
  {
    path: 'customers-search',
    loadChildren: () => import('./pages/customers-search/customers-search.module').then(m => m.CustomersSearchPageModule)
  },
  {
    path: 'vehicles-detail',
    loadChildren: () => import('./pages/vehicles-detail/vehicles-detail.page.module').then(m => m.VehiclesDetailPageModule)
  },
  {
    path: 'customers-detail',
    loadChildren: () => import('./pages/customers-detail/customers-detail.page.module').then(m => m.CustomersDetailPageModule)
  },
  {
    path: 'departments-detail',
    loadChildren: () => import('./pages/departments-detail/departments-detail.page.module').then(m => m.DepartmentDetailPageModule)
  },
  {
    path: 'drivers-search',
    loadChildren: () => import('./pages/utilizers-search/utilizers-search.page.module').then(m => m.UtilizersSearchPageModule)
  },
  {
    path: 'drivers-detail',
    loadChildren: () => import('./pages/utilizers-detail/utilizers-detail.page.module').then(m => m.UtilizersDetailPageModule)
  },
  {
    path: 'transactions-detail',
    loadChildren: () => import('./pages/transactions-detail/transaction-detail.page.module').then(m => m.TransactionDetailPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.page.module').then(m => m.DashboardPageModule)
  },
  {
    path: 'empty',
    loadChildren: () => import('./pages/empty/empty.page.module').then(m => m.EmptyPageModule)
  },
  {
    path: 'users-detail',
    loadChildren: () => import('./pages/users-detail/users-detail.page.module').then(m => m.UsersSearchPageModule)
  },
  {
    path: 'billings-search',
    loadChildren: () => import('./pages/billings-search/billings-search.module').then(m => m.BillingsSearchPageModule)
  },
  {
    path: 'billings-detail',
    loadChildren: () => import('./pages/billings-detail/billings-detail.module').then(m => m.BillingsDetailPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.page.module').then(m => m.AccountPageModule)
  },
  {
    path: 'lessees-search',
    loadChildren: () => import('./pages/lessees-search/lessees-search.module').then(m => m.LesseesSearchPageModule)
  },
  {
    path: 'lessees-detail',
    loadChildren: () => import('./pages/lessees-detail/lessees-detail.page.module').then(m => m.LesseeDetailPageModule)
  },
  {
    path: 'contracts-search',
    loadChildren: () => import('./pages/contracts-search/contracts-search.module').then(m => m.ContractsSearchPageModule)
  },
  {
    path: 'contracts-detail',
    loadChildren: () => import('./pages/contracts-detail/contracts-detail.page.module').then(m => m.ContractDetailPageModule)
  },
  {
    path: 'departments-hierarchy',
    loadChildren: () => import('./pages/customers-hierarchy/customers-hierarchy.page.module').then(m => m.CustomersDetailPageModule)
  },
  {
    path: 'lessees-hierarchy',
    loadChildren: () => import('./pages/customers-hierarchy/customers-hierarchy.page.module').then(m => m.CustomersDetailPageModule)
  },
  ,
  {
    path: 'transactions-export',
    loadChildren: () => import('./pages/transactions-export/transactions-export.page.module').then(m => m.TransactionsExportPageModule)
  },
  {
    path: 'transactions-lots-search',
    loadChildren: () => import('./pages/transactions-lots-search/transactions-lots-search.module').then(m => m.TransactionsLotsSearchPageModule)
  },
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
