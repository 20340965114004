import { NgModule } from '@angular/core';
import { InputPromptComponent } from './input-prompt.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateFormatPipeModule } from 'src/app/pipes/date-format/date-format.pipe.module';
import { CurrencyFormatPipeModule } from 'src/app/pipes/currency/currency.pipe.module';
import { IonicSelectableComponent } from 'ionic-selectable';
import { NGX_MAT_DATE_FORMATS, NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, NgxMatMomentAdapter, NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { getDateFormat, getDateTimeFormat } from 'src/app/services/mfeApp.service';
@NgModule({
    declarations: [
        InputPromptComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        DateFormatPipeModule,
        CurrencyFormatPipeModule,
        IonicSelectableComponent,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatMomentModule,
        MatDatepickerModule,
    ],
    exports: [
        InputPromptComponent
    ],
      providers: [
        { provide: NGX_MAT_DATE_FORMATS, useValue: getDateTimeFormat() },
        {
            provide: DateAdapter,
            useClass: NgxMatMomentAdapter,
            deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: getDateFormat() },
    ],
})
export class InputPromptComponentModule { }