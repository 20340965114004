import { NgModule } from '@angular/core';
import { MotivationPromptComponent } from './motivation-prompt.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateFormatPipeModule } from 'src/app/pipes/date-format/date-format.pipe.module';
import { CurrencyFormatPipeModule } from 'src/app/pipes/currency/currency.pipe.module';
@NgModule({
    declarations: [
        MotivationPromptComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        DateFormatPipeModule,
        CurrencyFormatPipeModule
    ],
    exports: [
        MotivationPromptComponent
    ]
})
export class MotivationPromptComponentModule { }