import { NgModule } from '@angular/core';
import { IonicModule } from"@ionic/angular";
import { CurrencyFormatPipe } from './currency.pipe';
@NgModule({
    declarations: [
        CurrencyFormatPipe
    ],
    imports: [
        IonicModule
    ],
    exports: [
        CurrencyFormatPipe
    ]
})
export class CurrencyFormatPipeModule {}