import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';

import { IonContent, IonRouterOutlet, IonSplitPane, Platform } from '@ionic/angular';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PopoverController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Location } from "@angular/common";
import { HttpHeaderResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { MicroFEService } from 'pssfe-micro-fe-service';
import { MfeUtilityService } from './services/mfe-utility.service';
import { MfeAppService } from './services/mfeApp.service';
import { TollCommonService } from './services/toll-common.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  //language
  public language = "it";
  //fullscreen
  public fullScreen;
  //scroll
  private lastScrollTop = 0;
  public scrollDown: boolean;
  private throttleId;
  public isSmallDevice: boolean;

  constructor(
    private platform: Platform,
    private router: Router,
    public menuCtrl: MenuController,
    public translate: TranslateService,
    public popoverCtrl: PopoverController,
    public alertCtrl: AlertController,
    private storage: Storage,
    public location: Location,
    public domSanitizer: DomSanitizer,
    private microFeSrv: MicroFEService,
    private mfeUtilitySrv: MfeUtilityService,
    private mfeAppSrv: MfeAppService,
    private _tollCommonSrv: TollCommonService
  ) {
    /*console.log("###")
    console.log(window.location.pathname)
    console.log("###")*/
    //setup app
    let context = this;
    this.initializeApp();
    this.router.onSameUrlNavigation = 'reload';
    this.router.errorHandler = (error: any) => {
      console.log("###");
      console.log(error);
      console.log("###");
      this.microFeSrv.goPageNotFound();
    }
    this.language = this.microFeSrv.getLanguage();
    this.isSmallDevice = this.microFeSrv.getIsMobile();
    //subscribe navigation end
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        //set scroll
        this.setScroll();
      };
    });
    //window resize
    window.addEventListener('resize', function () {
      //set scroll
      context.isSmallDevice = context.microFeSrv.getIsMobile();
    })
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    //table events
    this.mfeAppSrv.initEvents();
  }

  ngOnDestroy() {
  }

  initializeApp() {

  }

  /* throttle */
  private throttleFunction(func, delay) {
    if (this.throttleId) {
      return
    }
    this.throttleId = setTimeout(() => {
      func();
      this.throttleId = undefined;
    }, delay)
  }

  //setScroll
  private setScroll() {
    let context = this;
    setTimeout(() => {
      context.scrollDown = false;
      let mainContent = document.getElementById("main-content");
      let ionContent = mainContent ? mainContent.getElementsByTagName("ion-content")[0] : null;
      let ionContentShadowRoot = ionContent ? ionContent.shadowRoot : null;
      let innerScroll = ionContentShadowRoot ? ionContentShadowRoot.querySelectorAll(".inner-scroll") : null;
      let st, scrollHeight;
      if (innerScroll) {
        innerScroll[0].addEventListener("scroll", function (event) {
          context.throttleFunction(() => {
            if (context.isSmallDevice) {
              st = innerScroll[0].scrollTop;
              scrollHeight = innerScroll[0].scrollHeight - (innerScroll[0] as HTMLDivElement).offsetHeight;
              //bottom
              if (st > (scrollHeight - 10)) {

              } else if (st > context.lastScrollTop) {
                context.scrollDown = true;
              } else {
                context.scrollDown = false;
              }
              context.lastScrollTop = st <= 0 ? 0 : st;
            } else {
              context.scrollDown = false;
            }
          }, 100);
        });
      }
    }, 1000)
  }
}
