import { NgModule } from '@angular/core';
import { TableSettingsComponent } from './table-settings.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { IonicSelectableComponent } from 'ionic-selectable';
import { MatTabsModule } from '@angular/material/tabs';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
    declarations: [
        TableSettingsComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatExpansionModule,
        IonicSelectableComponent,
        MatTabsModule,
        DragDropModule
    ],
    exports: [
        TableSettingsComponent
    ]
})
export class TableSettingsComponentModule { }