import { NgxMatDateFormats } from '@angular-material-components/datetime-picker';
import { Injectable } from '@angular/core';
import { MatDateFormats } from '@angular/material/core';
import { BehaviorSubject, fromEvent, interval, timer } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MfeAppService {
    //domEventSubscription
    public domEvent: BehaviorSubject<DomEvent> = new BehaviorSubject<DomEvent>(null);
    public domEventObservable = this.domEvent.asObservable();

    constructor() {
    }
    //-- DEVICE --//
    public isMobile() {
        return window.innerWidth <= 576 ? true : false;
    }

    //--- DOM EVENTS ---//
    public initEvents() {
        fromEvent(document, 'mousedown')
            .subscribe((event) => {
                this.domEvent.next({ eventType: 'mousedown', event: event });
            })
        fromEvent(document, 'mousemove')
            .subscribe((event) => {
                this.domEvent.next({ eventType: 'mousemove', event: event });
            })
        fromEvent(document, 'mouseup')
            .subscribe((event) => {
                this.domEvent.next({ eventType: 'mouseup', event: event });
            })
    }
    //--- END DOM EVENTS ---//
}
export function getDateFormat() {
    let dateFormat: MatDateFormats = {
        parse: {
            dateInput: "DD/MM/YYYY"
        },
        display: {
            dateInput: "DD/MM/YYYY",
            monthYearLabel: "MMM YYYY",
            dateA11yLabel: "LL",
            monthYearA11yLabel: "MMMM YYYY"
        }
    };
    if (['en-US'].includes(navigator.languages[0]))
        dateFormat = {
            parse: {
                dateInput: "MM/DD/YYYY"
            },
            display: {
                dateInput: "MM/DD/YYYY",
                monthYearLabel: "MMM YYYY",
                dateA11yLabel: "LL",
                monthYearA11yLabel: "MMMM YYYY"
            }
        }
    return dateFormat;
}
export function getDateTimeFormat() {
    let dateFormat: NgxMatDateFormats = {
        parse: {
            dateInput: "DD/MM/YYYY HH:mm"
        },
        display: {
            dateInput: "DD/MM/YYYY HH:mm",
            monthYearLabel: "MMM YYYY",
            dateA11yLabel: "LL",
            monthYearA11yLabel: "MMMM YYYY"
        }
    };
    if (['en-US'].includes(navigator.languages[0]))
        dateFormat = {
            parse: {
                dateInput: "MM/DD/YYYY HH:mm"
            },
            display: {
                dateInput: "MM/DD/YYYY HH:mm",
                monthYearLabel: "MMM YYYY",
                dateA11yLabel: "LL",
                monthYearA11yLabel: "MMMM YYYY"
            }
        }
    return dateFormat;
}
export interface DomEvent{
    event: any,
    eventType: 'mousedown' | 'mousemove' | 'mouseup'
}