import { NgModule } from '@angular/core';
import { AddRolePromptComponent } from './add-role-prompt.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateFormatPipeModule } from 'src/app/pipes/date-format/date-format.pipe.module';
import { CurrencyFormatPipeModule } from 'src/app/pipes/currency/currency.pipe.module';
import { IonicSelectableComponent } from 'ionic-selectable';
@NgModule({
    declarations: [
        AddRolePromptComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        DateFormatPipeModule,
        CurrencyFormatPipeModule,
        IonicSelectableComponent
    ],
    exports: [
        AddRolePromptComponent
    ]
})
export class AddRolePromptComponentModule { }