import { NgModule } from '@angular/core';
import { DashboardControlComponent } from './dashboard-control.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateFormatPipeModule } from 'src/app/pipes/date-format/date-format.pipe.module';
import { CurrencyFormatPipeModule } from 'src/app/pipes/currency/currency.pipe.module';
import { IonicSelectableComponent } from 'ionic-selectable';
import { NgChartsModule } from 'ng2-charts';
import { NumberSeparatorPipeModule } from 'src/app/pipes/number-separator/number-separator.pipe.module';
@NgModule({
    declarations: [
        DashboardControlComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        DateFormatPipeModule,
        CurrencyFormatPipeModule,
        IonicSelectableComponent,
        NgChartsModule,
        NumberSeparatorPipeModule
    ],
    exports: [
        DashboardControlComponent
    ]
})
export class DashboardControlComponentModule { }