import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import BaseLinksJson from 'src/assets/mfe-links.json';
import { TranslateService } from '@ngx-translate/core';
import jwt_decode from 'jwt-decode';
import { MicroFEService } from 'pssfe-micro-fe-service';
@Injectable({
    providedIn: 'root'
})
export class MfeUtilityService {
    private currentEnvironment;
    public links = {
        client: "",
        server: "",
        chathub: "",
        apigateway: ""
    };

    constructor(private translate: TranslateService, private MicroFESrv: MicroFEService) {
        //services
        if (environment["production"]) {
            this.links.server = BaseLinksJson.prod.server;
            this.links.apigateway = BaseLinksJson.prod.apigateway;
            this.currentEnvironment = "production";
        }
        else if (environment["test"]) {
            this.links.server = BaseLinksJson.test.server;
            this.links.apigateway = BaseLinksJson.test.apigateway;
            this.currentEnvironment = "test";
        }
        else if (environment["stage"]) {
            this.links.server = BaseLinksJson.stage.server;
            this.links.apigateway = BaseLinksJson.stage.apigateway;
            this.currentEnvironment = "stage";
        }
        else if (environment["local"]) {
            this.links.server = BaseLinksJson.local.server;
            this.links.apigateway = BaseLinksJson.local.apigateway;
            this.currentEnvironment = "local";
        }
        else {
            this.links.server = BaseLinksJson.local.server;
            this.links.apigateway = BaseLinksJson.local.apigateway;
            this.currentEnvironment = "local";
        }
    }

    //getRequestError
    public getRequestError(requestResponse) {
        return new Promise((resolve) => {
            this.translate.get('errors').subscribe((errors: any) => {
                // string
                if (requestResponse && typeof requestResponse == 'string') {
                    resolve(requestResponse)
                }
                //requestResponse?.error?.error
                else if(requestResponse?.error?.error){
                    resolve(requestResponse.error.error)
                }
                //errore tradotto da codice errore
                else if (requestResponse && requestResponse.error && requestResponse.error.value && requestResponse.error.value.code)
                    resolve(errors[requestResponse.error.value.code] ? errors[requestResponse.error.value.code] : errors["com-error"]);
                //requestResponse.error.errors
                else if (requestResponse && requestResponse.error && requestResponse.error.errors && requestResponse.error.errors.length > 0) {
                    resolve(requestResponse.error.errors)
                }
                //error string
                else if (requestResponse && requestResponse.error && typeof requestResponse.error == 'string') {
                    resolve(requestResponse.error)
                }
                //error.Message
                else if (requestResponse && requestResponse.error && requestResponse.error.Message)
                    resolve(requestResponse.error.Message)
                //error.errorDisplayMessage
                else if (requestResponse && requestResponse.error && requestResponse.error.errorDisplayMessage)
                    resolve(requestResponse.error.errorDisplayMessage)
                //error.messaggio
                else if (requestResponse && requestResponse.error && requestResponse.error.messaggio)
                    resolve(requestResponse.error.messaggio)
                //message
                else if (requestResponse && requestResponse.message)
                    resolve(requestResponse.message)
                //tutto il resto
                else if (requestResponse && requestResponse.error)
                    resolve(requestResponse.error)
                //stringa
                else if (typeof requestResponse == 'string')
                    resolve(requestResponse)
                //nesun codice
                else
                    resolve(errors["1"])
            });
        })
    };

    //is touchscreen device
    public isTouchScreenDevice() {
        return ('ontouchstart' in window || navigator.maxTouchPoints) && screen.width < 700;
    };

    //getCurrentRoleId
    public getCurrentRoleId() {
        let tokenObject = this.decodeToken(), roleId;
        let unicName: string = tokenObject ? tokenObject["unique_name"] : null;
        roleId = unicName ? unicName.split("|")[1] : null;
        return roleId != null && roleId != undefined ? parseInt(roleId) : null;
    }
    //getCurrentUserId
    public getCurrentUserId() {
        let tokenObject = this.decodeToken(), userId;
        let unicName: string = tokenObject ? tokenObject["unique_name"] : null;
        userId = unicName ? unicName.split("|")[0] : null;
        return userId != null && userId != undefined ? parseInt(userId) : null;
    }
    //getModules
    public getModules() {
        let modules = this.decodeToken();
        modules = modules ? modules["modules"] : null;
        return modules;
    }
    //decodeToken
    private async decodeToken() {
        let token = (await this.MicroFESrv.getSessionState())?.userData?.token;
        try {
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }
    //get environment
    public getEnvironment = () => {
        return this.currentEnvironment;
    }

}
